const fetcher = async (method, url, params, values) => {
  const config = {
    //   mode: 'no-cors',
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (method === "GET") {
    for (const param of params) {
      url = url.replace("{" + param + "}", values[param]);
    }
  }

  if (method === "POST") {
    config.body = JSON.stringify(values);
  }

  return fetch(url, config).then((res) => {
    try {
      if (res.ok) {
        return res.json();
      }
    } catch (e) {
      throw Error("Error while fetching data");
    }
  })
};

const ServerCall = {
  call: async({method, params, url}, values) => {
    return fetcher(method, url, params, values);
  },
};

export default ServerCall;