import {initializeApp} from 'firebase/app'
import {GoogleAuthProvider, getAuth, signInWithPopup, signOut} from 'firebase/auth'
// import {getMessaging, getToken, onMessage} from 'firebase/messaging'
// import BroadcastNotification from './BroadcastNotification'

const firebaseConfig = {
  apiKey: "AIzaSyDda1gfIiOvQi39Wzbm66Z0ogKLNAt66Qo",
  authDomain: "pro-mgl-maris.firebaseapp.com",
  projectId: "pro-mgl-maris",
  storageBucket: "pro-mgl-maris.appspot.com",
  messagingSenderId: "571589956681",
  appId: "1:571589956681:web:c550ede162e375f0d89b98",
  measurementId: "G-XWM9PV69HF"
};

// const firebaseMessagingKey = 'BGeVT9-F_0cYhmAUGo7amuDDxkExi8-Bpha89q4CJHZqdYFJUvRq2tKoWaPoaX50HhhceRSCRuuVLTb-lMZc3yI'

const app               = initializeApp(firebaseConfig)
const auth              = getAuth(app)
const googleProvider    = new GoogleAuthProvider()
// const messaging         = getMessaging(app)
// const backgroundChannel = new BroadcastChannel('id.hanshq.archangel')

//
//
// authentication
//================================================================================================
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
    return res.user
  } catch (error) {
    console.error('ServerFirebase.js::signInWithGoogle', error)
  }
  return false
}

const signOutFromGoogle = async () => {
  try {
    signOut(auth)
    return true
  } catch (error) {
    console.error('ServerFirebase.js::signOutWithGoogle', error)
  }
  return false
}

const getIdTokenResult = async () => {
  try {
    if (!auth.currentUser) throw Error('invalid user')
    const userIdentity = await auth.currentUser.getIdTokenResult()
    return userIdentity
  } catch (error) {
    console.log('ServerFirebase::getIdTokenResult', error)
  }

  return null;
}

//
//
// messaging
//================================================================================================
const getMessagingToken = async () => {
  let currentToken = null
  try {
    // currentToken = await getToken(messaging, {vapidKey: firebaseMessagingKey})
  } catch (error) {
    console.log('ServerFirebase::getMessagingToken', error)
  }
  return currentToken
}

// onMessage(messaging, (payload) => {
//   BroadcastNotification.handleMessage(auth.currentUser, payload)
// })

// backgroundChannel.onmessage = (backgroundEvent) => {
//   BroadcastNotification.handleMessage(auth.currentUser, backgroundEvent.data)
// }

//
//
// interface
//================================================================================================
// onMessageListener()
const ServerFirebase = {
  googleSignIn: async () => {
    return signInWithGoogle()
  },
  googleSignOut: async () => {
    return signOutFromGoogle()
  },
  getIdTokenResult: async () => {
    return getIdTokenResult()
  },
  getMessagingToken: async () => {
    return getMessagingToken()
  },
}

export default ServerFirebase