import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

import Bowser from 'bowser'

import ServerFirebase from 'utils/hhq/ServerFirebase'
import ServerApiAuth from 'utils/hhq/ServerApiAuth'

import acronym from 'utils/acronym'

function useAuth() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const query = useQuery()
	const { auth_token, signedIn } = useSelector((state) => state.auth.session)
	const userInfo = useSelector((state) =>  state.auth.user)

	const userpassSignIn = async (values) => {
		let userpassMessage = ''

		try {
			// device check
			const clientBrowser = Bowser.getParser(window.navigator.userAgent)

			// login check
			const respSignin = await ServerApiAuth.signInUserPassword(
																							{username: values.userName, password: values.password}, 
																							{location: '-', platform: clientBrowser.getPlatform().type, os_name: clientBrowser.getOSName(), os_version: clientBrowser.getOSVersion()?clientBrowser.getOSVersion():'-', browser_name: clientBrowser.getBrowserName(), browser_version: clientBrowser.getBrowserVersion()}
												 			 )

			if (respSignin.error_id > 0) throw Error(respSignin.error_message)

			// get profile and roles
      const userProfile = await ServerApiAuth.getUserProfile(
																								{auth_token: respSignin.data.auth_token}
																							)
 
			// save auth login
			const userRoles = []
			userRoles.push(userProfile.data.roles)

			dispatch(onSignInSuccess(respSignin.data.auth_token))
			dispatch(setUser({ 
				email: userProfile.data.email,
				nama: userProfile.data.karyawan_nama, //userProfile.data.nama,
				mobile: userProfile.data.mobile,
				photo_url: 'https://ui-avatars.com/api/?name='+acronym(userProfile.data.karyawan_nama)+'&background=0D8ABC&color=fff&bold=true&size=128', //userProfile.photo_url,
				register_tanggal: userProfile.data.register_tanggal,
				perusahaan_cabang: userProfile.data.perusahaan_cabang,
				perusahaan_jabatan: userProfile.data.perusahaan_jabatan,
				perusahaan_divisi: userProfile.data.perusahaan_divisi,
				perusahaan_bagian: userProfile.data.perusahaan_bagian,
				
				is_introduction: false,
				authority: [...userRoles],
				roles: [...userRoles],
			}))

			// go to login page or redirect 
			const redirectUrl = query.get(REDIRECT_URL_KEY)
			navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
		}
		catch (errors) {
			userpassMessage = errors.toString()
		}
		finally {
		}

		return {
			status: userpassMessage === '' ? 'success':'failed',
			message: userpassMessage
		}
	}

	const googleSignIn = async () => {
		let firebaseMessage = ''
		try {
      // firebase login
      const userGoogle = await ServerFirebase.googleSignIn()
      if (!userGoogle) throw Error('Error on signin');

      const userIdentity = await userGoogle.getIdTokenResult()

      // register if not registered
      if (!userIdentity.claims.registered) {
        await ServerApiAuth.register({firebase_auth_token: userIdentity.token})
      }

			// device check
			const clientBrowser = Bowser.getParser(window.navigator.userAgent)

			// login check
			const respSignin = await ServerApiAuth.signInFirebase(
																							{firebase_auth_token: userIdentity.token}, 
																							{location: '-', platform: clientBrowser.getPlatform().type, os_name: clientBrowser.getOSName(), os_version: clientBrowser.getOSVersion(), browser_name: clientBrowser.getBrowserName(), browser_version: clientBrowser.getBrowserVersion()}
												 			 )
															 
			if (respSignin.error_id > 0) throw Error(respSignin.error_message)

			// get profile and roles
      const userProfile = await ServerApiAuth.getUserProfile(
																								{auth_token: respSignin.data.auth_token}
																							)
 
			// save auth login
			const userRoles = []
			userRoles.push(userProfile.data.roles)

			dispatch(onSignInSuccess(respSignin.data.auth_token))
			dispatch(setUser({ 
				email: userProfile.data.email,
				nama: userProfile.data.nama,
				mobile: userProfile.data.mobile,
				photo_url: userProfile.photo_url,
				register_tanggal: userProfile.data.register_tanggal,
				perusahaan_cabang: userProfile.data.perusahaan_cabang,
				perusahaan_jabatan: userProfile.data.perusahaan_jabatan,
				perusahaan_divisi: userProfile.data.perusahaan_divisi,
				perusahaan_bagian: userProfile.data.perusahaan_bagian,
				
				is_introduction: false,
				authority: [...userRoles],
				roles: [...userRoles],
			}))

			// go to login page or redirect 
			const redirectUrl = query.get(REDIRECT_URL_KEY)
			navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
		} catch (errors) {
			console.log('useAuth::googleSignIn', errors.toString())
			firebaseMessage = errors.toString()
		} finally {
		}

		return {
			status: firebaseMessage === '' ? 'success':'failed',
			message: firebaseMessage
		}
	}

	const handleSignOut = ()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

	const signOut = async () => {
		try {
			// logout check
			const respSignout = await ServerApiAuth.signOut({auth_token: auth_token})

			await ServerFirebase.googleSignOut()
			handleSignOut()
		} catch(e) {

		}
	}

	const getProfile = async () => {
		const response = await ServerApiAuth.getUserProfile({auth_token: auth_token})
		return response
	}

	const updateProfile = async oData => {
		const response = await ServerApiAuth.postUpdateProfile({auth_token: auth_token}, oData)

		if (response.error_id === 0) {
			dispatch(setUser({ 
				...userInfo,
				nama: response.data.nama
			}))
		}
		
		return response
	}
    
	return {
		authenticated: auth_token && signedIn,
		userpassSignIn,
		googleSignIn,
		signOut,
		getProfile,
		updateProfile,
	}
}

export default useAuth