import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  // ---- bawaan
  // avatar: '',
  // userName: '',
  // email: '',
  // authority: [],
  // ---- hanshq
  authority: [],
  email: '',
  is_introduction: false,
  mobile: '',
  nama: '',
  photo_url: '',
  register_tanggal: '',
  roles: [],
  perusahaan_cabang: '',
  perusahaan_jabatan: '',
  perusahaan_divisi: '',
  perusahaan_bagian: '',
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
    setUser: (_, action) => action.payload,
    userLoggedOut: () => initialState,
	},
})

export const { setUser } = userSlice.actions

export default userSlice.reducer