// const BASE_PATH = "http://localhost:9001/"
const BASE_PATH = "https://simaris-api.hanshq.id/"

const api_calls = {
  endpoint: {
    url : `${BASE_PATH}endpoint`,
    params: [],
    method: 'POST',
  },
  getdata: {
    url : `${BASE_PATH}getdata`,
    params: [],
    method: 'POST',
  },
  createdata: {
    url : `${BASE_PATH}postdata`,
    params: [],
    method: 'POST',
  },
  updatedata: {
    url : `${BASE_PATH}putdata`,
    params: [],
    method: 'POST',
  },
  deletedata: {
    url : `${BASE_PATH}deletedata`,
    params: [],
    method: 'POST',
  },
}

export default api_calls