export const COMMAND_SERVER = {
  server_ping         : 'SERVER-PING',
  
  // nologin
  register            : 'REGISTER',

  // login
  user_post_login_usernamepassword    : 'USER-POST-LOGIN-USERNAME-PASSWORD',
  user_post_login_firebase            : 'USER-POST-LOGIN-FIREBASE',
  user_post_logout                    : 'USER-POST-LOGOUT',
  user_get_profile                    : 'USER-GET-PROFILE',
  user_get_roles                      : 'USER-GET-ROLES',
  user_update_profile                 : 'USER-UPDATE-PROFILE',

  // system
  get_system_user                     : 'GET-SYSTEM-USER', 
  pos_system_user                     : 'POS-SYSTEM-USER', 
  put_system_user                     : 'PUT-SYSTEM-USER', 

  get_system_privileges               : 'GET-SYSTEM-PRIVILEGES', 
  pos_system_privileges               : 'POS-SYSTEM-PRIVILEGES', 
  put_system_privileges               : 'PUT-SYSTEM-PRIVILEGES', 

  get_system_event                    : 'GET-SYSTEM-EVENT', 
  pos_system_event                    : 'POS-SYSTEM-EVENT', 
  put_system_event                    : 'PUT-SYSTEM-EVENT', 

  get_system_sp_name                  : 'GET-SYSTEM-SP-NAME', 

  // master
  get_master_cabang                   : 'GET-MASTER-CABANG', 
  pos_master_cabang                   : 'POS-MASTER-CABANG', 
  put_master_cabang                   : 'PUT-MASTER-CABANG', 

  get_master_perusahaan               : 'GET-MASTER-PERUSAHAAN', 
  pos_master_perusahaan               : 'POS-MASTER-PERUSAHAAN', 
  put_master_perusahaan               : 'PUT-MASTER-PERUSAHAAN', 

  // system development
  PBS_GET_DAFTARTUGAS_HRD_DATA_KARYAWANSYSUSER    : 'PBS-GET-DAFTARTUGAS-HRD-DATA-KARYAWANSYSUSER',
  
  get_ref_pbs_form_status             : 'GET-REF-PBS-FORM-STATUS',
  pos_ref_pbs_form_status             : 'POS-REF-PBS-FORM-STATUS',
  put_ref_pbs_form_status             : 'PUT-REF-PBS-FORM-STATUS',

  get_data_pbs_form                   : 'GET-DATA-PBS-FORM',
  get_data_pbs_form_rules             : 'GET-DATA-PBS-FORM-RULES',
  get_data_pbs_form_rules_lampiran    : 'GET-DATA-PBS-FORM-RULES-LAMPIRAN',
  get_data_pbs_form_rules_membuat     : 'GET-DATA-PBS-FORM-RULES-MEMBUAT',
  get_data_pbs_form_rules_memeriksa   : 'GET-DATA-PBS-FORM-RULES-MEMERIKSA',
  get_data_pbs_form_rules_menyetujui  : 'GET-DATA-PBS-FORM-RULES-MENYETUJUI',
  get_data_pbs_form_rules_tujuan      : 'GET-DATA-PBS-FORM-RULES-TUJUAN',

  pos_data_pbs_form                   : 'POS-DATA-PBS-FORM',
  pos_data_pbs_form_rules             : 'POS-DATA-PBS-FORM-RULES',
  pos_data_pbs_form_rules_lampiran    : 'POS-DATA-PBS-FORM-RULES-LAMPIRAN',
  pos_data_pbs_form_rules_membuat     : 'POS-DATA-PBS-FORM-RULES-MEMBUAT',
  pos_data_pbs_form_rules_memeriksa   : 'POS-DATA-PBS-FORM-RULES-MEMERIKSA',
  pos_data_pbs_form_rules_menyetujui  : 'POS-DATA-PBS-FORM-RULES-MENYETUJUI',
  pos_data_pbs_form_rules_tujuan      : 'POS-DATA-PBS-FORM-RULES-TUJUAN',

  put_data_pbs_form                   : 'PUT-DATA-PBS-FORM',
  put_data_pbs_form_rules             : 'PUT-DATA-PBS-FORM-RULES',

  del_data_pbs_form_rules_lampiran    : 'DEL-DATA-PBS-FORM-RULES-LAMPIRAN',
  del_data_pbs_form_rules_membuat     : 'DEL-DATA-PBS-FORM-RULES-MEMBUAT',
  del_data_pbs_form_rules_memeriksa   : 'DEL-DATA-PBS-FORM-RULES-MEMERIKSA',
  del_data_pbs_form_rules_menyetujui  : 'DEL-DATA-PBS-FORM-RULES-MENYETUJUI',
  del_data_pbs_form_rules_tujuan      : 'DEL-DATA-PBS-FORM-RULES-TUJUAN',

  // referensi bd
  get_ref_bd_mataangin                : 'GET-REF-BD-MATAANGIN', 
  pos_ref_bd_mataangin                : 'POS-REF-BD-MATAANGIN', 
  put_ref_bd_mataangin                : 'PUT-REF-BD-MATAANGIN', 

  get_ref_bd_statussurvey             : 'GET-REF-BD-STATUSSURVEY', 
  pos_ref_bd_statussurvey             : 'POS-REF-BD-STATUSSURVEY', 
  put_ref_bd_statussurvey             : 'PUT-REF-BD-STATUSSURVEY', 

  get_ref_bd_jenispembayaran          : 'GET-REF-BD-JENISPEMBAYARAN', 
  pos_ref_bd_jenispembayaran          : 'POS-REF-BD-JENISPEMBAYARAN', 
  put_ref_bd_jenispembayaran          : 'PUT-REF-BD-JENISPEMBAYARAN', 

  // referensi prc
  get_ref_prc_progresstipe            : 'GET-REF-PRC-PROGRESSTIPE', 
  pos_ref_prc_progresstipe            : 'POS-REF-PRC-PROGRESSTIPE', 
  put_ref_prc_progresstipe            : 'PUT-REF-PRC-PROGRESSTIPE', 

  get_ref_prc_progresstipekomponen    : 'GET-REF-PRC-PROGRESSTIPEKOMPONEN', 
  pos_ref_prc_progresstipekomponen    : 'POS-REF-PRC-PROGRESSTIPEKOMPONEN', 
  put_ref_prc_progresstipekomponen    : 'PUT-REF-PRC-PROGRESSTIPEKOMPONEN', 
  del_ref_prc_progresstipekomponen    : 'DEL-REF-PRC-PROGRESSTIPEKOMPONEN', 

  get_ref_prc_spesifikasi             : 'GET-REF-PRC-SPESIFIKASI', 
  pos_ref_prc_spesifikasi             : 'POS-REF-PRC-SPESIFIKASI', 
  put_ref_prc_spesifikasi             : 'PUT-REF-PRC-SPESIFIKASI', 

  get_ref_prc_spesifikasikomponen     : 'GET-REF-PRC-SPESIFIKASIKOMPONEN', 
  pos_ref_prc_spesifikasikomponen     : 'POS-REF-PRC-SPESIFIKASIKOMPONEN', 
  put_ref_prc_spesifikasikomponen     : 'PUT-REF-PRC-SPESIFIKASIKOMPONEN', 
  del_ref_prc_spesifikasikomponen     : 'DEL-REF-PRC-SPESIFIKASIKOMPONEN', 

  get_ref_prc_jenisunit               : 'GET-REF-PRC-JENISUNIT', 
  pos_ref_prc_jenisunit               : 'POS-REF-PRC-JENISUNIT', 
  put_ref_prc_jenisunit               : 'PUT-REF-PRC-JENISUNIT', 

  get_ref_prc_qualitycontrol          : 'GET-REF-PRC-QUALITYCONTROL', 
  pos_ref_prc_qualitycontrol          : 'POS-REF-PRC-QUALITYCONTROL', 
  put_ref_prc_qualitycontrol          : 'PUT-REF-PRC-QUALITYCONTROL', 

  get_ref_prc_qualitycontrolkategori  : 'GET-REF-PRC-QUALITYCONTROLKATEGORI', 
  pos_ref_prc_qualitycontrolkategori  : 'POS-REF-PRC-QUALITYCONTROLKATEGORI', 
  put_ref_prc_qualitycontrolkategori  : 'PUT-REF-PRC-QUALITYCONTROLKATEGORI', 
  del_ref_prc_qualitycontrolkategori  : 'DEL-REF-PRC-QUALITYCONTROLKATEGORI', 

  get_ref_prc_qualitycontrolkomponen  : 'GET-REF-PRC-QUALITYCONTROLKOMPONEN', 
  pos_ref_prc_qualitycontrolkomponen  : 'POS-REF-PRC-QUALITYCONTROLKOMPONEN', 
  put_ref_prc_qualitycontrolkomponen  : 'PUT-REF-PRC-QUALITYCONTROLKOMPONEN', 
  del_ref_prc_qualitycontrolkomponen  : 'DEL-REF-PRC-QUALITYCONTROLKOMPONEN', 

  // referensi pur
  get_ref_pur_grade                   : 'GET-REF-PUR-GRADE', 
  pos_ref_pur_grade                   : 'POS-REF-PUR-GRADE', 
  put_ref_pur_grade                   : 'PUT-REF-PUR-GRADE', 

  get_ref_pur_gradetermin             : 'GET-REF-PUR-GRADETERMIN', 
  pos_ref_pur_gradetermin             : 'POS-REF-PUR-GRADETERMIN', 
  put_ref_pur_gradetermin             : 'PUT-REF-PUR-GRADETERMIN', 
  del_ref_pur_gradetermin             : 'DEL-REF-PUR-GRADETERMIN', 

  get_ref_pur_kotakab                 : 'GET-REF-PUR-KOTAKAB', 
  pos_ref_pur_kotakab                 : 'POS-REF-PUR-KOTAKAB', 
  put_ref_pur_kotakab                 : 'PUT-REF-PUR-KOTAKAB', 

  get_ref_pur_statusspk               : 'GET-REF-PUR-STATUSSPK', 
  pos_ref_pur_statusspk               : 'POS-REF-PUR-STATUSSPK', 
  put_ref_pur_statusspk               : 'PUT-REF-PUR-STATUSSPK', 

  get_ref_pur_statusbap               : 'GET-REF-PUR-STATUSBAP', 
  pos_ref_pur_statusbap               : 'POS-REF-PUR-STATUSBAP', 
  put_ref_pur_statusbap               : 'PUT-REF-PUR-STATUSBAP', 

  get_ref_pur_materialkategori        : 'GET-REF-PUR-MATERIALKATEGORI', 
  pos_ref_pur_materialkategori        : 'POS-REF-PUR-MATERIALKATEGORI', 
  put_ref_pur_materialkategori        : 'PUT-REF-PUR-MATERIALKATEGORI', 

  get_ref_pur_material                : 'GET-REF-PUR-MATERIAL', 
  pos_ref_pur_material                : 'POS-REF-PUR-MATERIAL', 
  put_ref_pur_material                : 'PUT-REF-PUR-MATERIAL', 

  get_ref_pur_materialharga           : 'GET-REF-PUR-MATERIALHARGA', 
  pos_ref_pur_materialharga           : 'POS-REF-PUR-MATERIALHARGA', 
  put_ref_pur_materialharga           : 'PUT-REF-PUR-MATERIALHARGA', 

  // referensi marketing
  get_ref_mkt_bank                    : 'GET-REF-MKT-BANK', 
  pos_ref_mkt_bank                    : 'POS-REF-MKT-BANK', 
  put_ref_mkt_bank                    : 'PUT-REF-MKT-BANK', 

  get_ref_mkt_jenisbiaya              : 'GET-REF-MKT-JENISBIAYA', 
  pos_ref_mkt_jenisbiaya              : 'POS-REF-MKT-JENISBIAYA', 
  put_ref_mkt_jenisbiaya              : 'PUT-REF-MKT-JENISBIAYA', 

  get_ref_mkt_jenisbiaya_komponen     : 'GET-REF-MKT-JENISBIAYA-KOMPONEN', 
  pos_ref_mkt_jenisbiaya_komponen     : 'POS-REF-MKT-JENISBIAYA-KOMPONEN', 
  put_ref_mkt_jenisbiaya_komponen     : 'PUT-REF-MKT-JENISBIAYA-KOMPONEN', 
  del_ref_mkt_jenisbiaya_komponen     : 'DEL-REF-MKT-JENISBIAYA-KOMPONEN', 

  get_ref_mkt_jenispromo              : 'GET-REF-MKT-JENISPROMO', 
  pos_ref_mkt_jenispromo              : 'POS-REF-MKT-JENISPROMO', 
  put_ref_mkt_jenispromo              : 'PUT-REF-MKT-JENISPROMO', 

  get_ref_mkt_jenispekerjaan          : 'GET-REF-MKT-JENISPEKERJAAN', 
  pos_ref_mkt_jenispekerjaan          : 'POS-REF-MKT-JENISPEKERJAAN', 
  put_ref_mkt_jenispekerjaan          : 'PUT-REF-MKT-JENISPEKERJAAN', 

  get_ref_mkt_jenispembayaran         : 'GET-REF-MKT-JENISPEMBAYARAN', 
  pos_ref_mkt_jenispembayaran         : 'POS-REF-MKT-JENISPEMBAYARAN', 
  put_ref_mkt_jenispembayaran         : 'PUT-REF-MKT-JENISPEMBAYARAN', 

  get_ref_mkt_jenistransaksi          : 'GET-REF-MKT-JENISTRANSAKSI', 
  pos_ref_mkt_jenistransaksi          : 'POS-REF-MKT-JENISTRANSAKSI', 
  put_ref_mkt_jenistransaksi          : 'PUT-REF-MKT-JENISTRANSAKSI', 

  get_ref_mkt_pipeline                : 'GET-REF-MKT-PIPELINE', 
  pos_ref_mkt_pipeline                : 'POS-REF-MKT-PIPELINE', 
  put_ref_mkt_pipeline                : 'PUT-REF-MKT-PIPELINE', 

  get_ref_mkt_statuspenjualan         : 'GET-REF-MKT-STATUSPENJUALAN', 
  pos_ref_mkt_statuspenjualan         : 'POS-REF-MKT-STATUSPENJUALAN', 
  put_ref_mkt_statuspenjualan         : 'PUT-REF-MKT-STATUSPENJUALAN', 

  // referensi keuangan
  get_ref_keu_bankpenerima            : 'GET-REF-KEU-BANKPENERIMA', 
  pos_ref_keu_bankpenerima            : 'POS-REF-KEU-BANKPENERIMA', 
  put_ref_keu_bankpenerima            : 'PUT-REF-KEU-BANKPENERIMA', 

  get_ref_keu_carapembayaran          : 'GET-REF-KEU-CARAPEMBAYARAN', 
  pos_ref_keu_carapembayaran          : 'POS-REF-KEU-CARAPEMBAYARAN', 
  put_ref_keu_carapembayaran          : 'PUT-REF-KEU-CARAPEMBAYARAN', 

  // referensi hrd
  get_ref_hrd_divisi                  : 'GET-REF-HRD-DIVISI', 
  pos_ref_hrd_divisi                  : 'POS-REF-HRD-DIVISI', 
  put_ref_hrd_divisi                  : 'PUT-REF-HRD-DIVISI', 

  get_ref_hrd_jabatan                 : 'GET-REF-HRD-JABATAN', 
  pos_ref_hrd_jabatan                 : 'POS-REF-HRD-JABATAN', 
  put_ref_hrd_jabatan                 : 'PUT-REF-HRD-JABATAN', 

  get_ref_hrd_pangkatgolongan         : 'GET-REF-HRD-PANGKATGOLONGAN', 
  pos_ref_hrd_pangkatgolongan         : 'POS-REF-HRD-PANGKATGOLONGAN', 
  put_ref_hrd_pangkatgolongan         : 'PUT-REF-HRD-PANGKATGOLONGAN', 

  get_ref_hrd_statuskepegawaian       : 'GET-REF-HRD-STATUSKEPEGAWAIAN', 
  pos_ref_hrd_statuskepegawaian       : 'POS-REF-HRD-STATUSKEPEGAWAIAN', 
  put_ref_hrd_statuskepegawaian       : 'PUT-REF-HRD-STATUSKEPEGAWAIAN', 

  get_ref_hrd_jenismutasi             : 'GET-REF-HRD-JENISMUTASI', 
  pos_ref_hrd_jenismutasi             : 'POS-REF-HRD-JENISMUTASI', 
  put_ref_hrd_jenismutasi             : 'PUT-REF-HRD-JENISMUTASI', 

  get_ref_hrd_jenissanksi             : 'GET-REF-HRD-JENISSANKSI', 
  pos_ref_hrd_jenissanksi             : 'POS-REF-HRD-JENISSANKSI', 
  put_ref_hrd_jenissanksi             : 'PUT-REF-HRD-JENISSANKSI', 

  get_ref_hrd_jenisabsensi            : 'GET-REF-HRD-JENISABSENSI', 
  pos_ref_hrd_jenisabsensi            : 'POS-REF-HRD-JENISABSENSI', 
  put_ref_hrd_jenisabsensi            : 'PUT-REF-HRD-JENISABSENSI', 

  get_ref_hrd_agama                   : 'GET-REF-HRD-AGAMA', 
  pos_ref_hrd_agama                   : 'POS-REF-HRD-AGAMA', 
  put_ref_hrd_agama                   : 'PUT-REF-HRD-AGAMA', 

  get_ref_hrd_jeniskelamin            : 'GET-REF-HRD-JENISKELAMIN', 
  pos_ref_hrd_jeniskelamin            : 'POS-REF-HRD-JENISKELAMIN', 
  put_ref_hrd_jeniskelamin            : 'PUT-REF-HRD-JENISKELAMIN', 

  get_ref_hrd_pendidikan              : 'GET-REF-HRD-PENDIDIKAN', 
  pos_ref_hrd_pendidikan              : 'POS-REF-HRD-PENDIDIKAN', 
  put_ref_hrd_pendidikan              : 'PUT-REF-HRD-PENDIDIKAN', 

  get_ref_hrd_statusperkawinan        : 'GET-REF-HRD-STATUSPERKAWINAN', 
  pos_ref_hrd_statusperkawinan        : 'POS-REF-HRD-STATUSPERKAWINAN', 
  put_ref_hrd_statusperkawinan        : 'PUT-REF-HRD-STATUSPERKAWINAN', 

  // bd
  get_data_bd_survey                  : 'GET-DATA-BD-SURVEY', 
  pos_data_bd_survey                  : 'POS-DATA-BD-SURVEY', 
  put_data_bd_survey                  : 'PUT-DATA-BD-SURVEY', 

  put_data_bd_survey_status           : 'PUT-DATA-BD-SURVEY-STATUS', 

  get_data_bd_survey_sertifikat       : 'GET-DATA-BD-SURVEY-SERTIFIKAT', 
  pos_data_bd_survey_sertifikat       : 'POS-DATA-BD-SURVEY-SERTIFIKAT', 
  put_data_bd_survey_sertifikat       : 'PUT-DATA-BD-SURVEY-SERTIFIKAT', 
  del_data_bd_survey_sertifikat       : 'DEL-DATA-BD-SURVEY-SERTIFIKAT', 

  get_data_bd_survey_pembayaran       : 'GET-DATA-BD-SURVEY-PEMBAYARAN', 
  pos_data_bd_survey_pembayaran       : 'POS-DATA-BD-SURVEY-PEMBAYARAN', 
  put_data_bd_survey_pembayaran       : 'PUT-DATA-BD-SURVEY-PEMBAYARAN', 
  del_data_bd_survey_pembayaran       : 'DEL-DATA-BD-SURVEY-PEMBAYARAN', 

  get_data_bd_survey_notes            : 'GET-DATA-BD-SURVEY-NOTES', 
  pos_data_bd_survey_notes            : 'POS-DATA-BD-SURVEY-NOTES', 
  put_data_bd_survey_notes            : 'PUT-DATA-BD-SURVEY-NOTES', 

  // prc
  get_data_prc_siteplan               : 'GET-DATA-PRC-SITEPLAN', 
  pos_data_prc_siteplan               : 'POS-DATA-PRC-SITEPLAN', 
  put_data_prc_siteplan               : 'PUT-DATA-PRC-SITEPLAN', 
  put_data_prc_siteplan_pengesahan    : 'PUT-DATA-PRC-SITEPLAN-PENGESAHAN', 

  get_data_prc_siteplan_dokumen       : 'GET-DATA-PRC-SITEPLAN-DOKUMEN', 
  pos_data_prc_siteplan_dokumen       : 'POS-DATA-PRC-SITEPLAN-DOKUMEN', 
  put_data_prc_siteplan_dokumen       : 'PUT-DATA-PRC-SITEPLAN-DOKUMEN', 
  del_data_prc_siteplan_dokumen       : 'DEL-DATA-PRC-SITEPLAN-DOKUMEN', 

  get_data_prc_siteplan_notes         : 'GET-DATA-PRC-SITEPLAN-NOTES', 
  pos_data_prc_siteplan_notes         : 'POS-DATA-PRC-SITEPLAN-NOTES', 
  put_data_prc_siteplan_notes         : 'PUT-DATA-PRC-SITEPLAN-NOTES', 

  get_data_prc_siteplan_unit          : 'GET-DATA-PRC-SITEPLAN-UNIT', 
  pos_data_prc_siteplan_unit          : 'POS-DATA-PRC-SITEPLAN-UNIT', 
  put_data_prc_siteplan_unit          : 'PUT-DATA-PRC-SITEPLAN-UNIT', 
  del_data_prc_siteplan_unit          : 'DEL-DATA-PRC-SITEPLAN-UNIT', 
  pos_data_prc_siteplan_unit_generate : 'POS-DATA-PRC-SITEPLAN-UNIT-GENERATE', 

  get_data_prc_siteplan_nonunit       : 'GET-DATA-PRC-SITEPLAN-NONUNIT', 
  pos_data_prc_siteplan_nonunit       : 'POS-DATA-PRC-SITEPLAN-NONUNIT', 
  put_data_prc_siteplan_nonunit       : 'PUT-DATA-PRC-SITEPLAN-NONUNIT', 
  del_data_prc_siteplan_nonunit       : 'DEL-DATA-PRC-SITEPLAN-NONUNIT', 

  get_data_prc_ordergambar            : 'GET-DATA-PRC-ORDERGAMBAR', 
  pos_data_prc_ordergambar            : 'POS-DATA-PRC-ORDERGAMBAR', 
  put_data_prc_ordergambar            : 'PUT-DATA-PRC-ORDERGAMBAR', 

  get_data_prc_ordergambar_unit       : 'GET-DATA-PRC-ORDERGAMBAR-UNIT', 
  pos_data_prc_ordergambar_unit       : 'POS-DATA-PRC-ORDERGAMBAR-UNIT', 
  put_data_prc_ordergambar_unit       : 'PUT-DATA-PRC-ORDERGAMBAR-UNIT', 
  del_data_prc_ordergambar_unit       : 'DEL-DATA-PRC-ORDERGAMBAR-UNIT', 

  get_data_prc_ordergambar_nonunit    : 'GET-DATA-PRC-ORDERGAMBAR-NONUNIT', 
  pos_data_prc_ordergambar_nonunit    : 'POS-DATA-PRC-ORDERGAMBAR-NONUNIT', 
  put_data_prc_ordergambar_nonunit    : 'PUT-DATA-PRC-ORDERGAMBAR-NONUNIT', 
  del_data_prc_ordergambar_nonunit    : 'DEL-DATA-PRC-ORDERGAMBAR-NONUNIT', 

  get_data_prc_ordergambar_notes      : 'GET-DATA-PRC-ORDERGAMBAR-NOTES', 
  pos_data_prc_ordergambar_notes      : 'POS-DATA-PRC-ORDERGAMBAR-NOTES', 

  get_data_prc_orderbangun            : 'GET-DATA-PRC-ORDERBANGUN', 
  pos_data_prc_orderbangun            : 'POS-DATA-PRC-ORDERBANGUN', 
  put_data_prc_orderbangun            : 'PUT-DATA-PRC-ORDERBANGUN', 

  get_data_prc_orderbangun_unit       : 'GET-DATA-PRC-ORDERBANGUN-UNIT', 
  pos_data_prc_orderbangun_unit       : 'POS-DATA-PRC-ORDERBANGUN-UNIT', 
  put_data_prc_orderbangun_unit       : 'PUT-DATA-PRC-ORDERBANGUN-UNIT', 
  del_data_prc_orderbangun_unit       : 'DEL-DATA-PRC-ORDERBANGUN-UNIT', 

  get_data_prc_orderbangun_nonunit    : 'GET-DATA-PRC-ORDERBANGUN-NONUNIT', 
  pos_data_prc_orderbangun_nonunit    : 'POS-DATA-PRC-ORDERBANGUN-NONUNIT', 
  put_data_prc_orderbangun_nonunit    : 'PUT-DATA-PRC-ORDERBANGUN-NONUNIT', 
  del_data_prc_orderbangun_nonunit    : 'DEL-DATA-PRC-ORDERBANGUN-NONUNIT', 

  get_data_prc_orderbangun_notes      : 'GET-DATA-PRC-ORDERBANGUN-NOTES', 
  pos_data_prc_orderbangun_notes      : 'POS-DATA-PRC-ORDERBANGUN-NOTES', 

  get_data_prc_orderpln               : 'GET-DATA-PRC-ORDERPLN', 
  pos_data_prc_orderpln               : 'POS-DATA-PRC-ORDERPLN', 
  put_data_prc_orderpln               : 'PUT-DATA-PRC-ORDERPLN', 

  get_data_prc_orderpln_unit          : 'GET-DATA-PRC-ORDERPLN-UNIT', 
  pos_data_prc_orderpln_unit          : 'POS-DATA-PRC-ORDERPLN-UNIT', 
  put_data_prc_orderpln_unit          : 'PUT-DATA-PRC-ORDERPLN-UNIT', 
  del_data_prc_orderpln_unit          : 'DEL-DATA-PRC-ORDERPLN-UNIT', 

  get_data_prc_orderpln_notes         : 'GET-DATA-PRC-ORDERPLN-NOTES', 
  pos_data_prc_orderpln_notes         : 'POS-DATA-PRC-ORDERPLN-NOTES',

  get_data_prc_orderpdam            : 'GET-DATA-PRC-ORDERPDAM', 
  pos_data_prc_orderpdam            : 'POS-DATA-PRC-ORDERPDAM', 
  put_data_prc_orderpdam            : 'PUT-DATA-PRC-ORDERPDAM', 

  get_data_prc_orderpdam_unit       : 'GET-DATA-PRC-ORDERPDAM-UNIT', 
  pos_data_prc_orderpdam_unit       : 'POS-DATA-PRC-ORDERPDAM-UNIT', 
  put_data_prc_orderpdam_unit       : 'PUT-DATA-PRC-ORDERPDAM-UNIT', 
  del_data_prc_orderpdam_unit       : 'DEL-DATA-PRC-ORDERPDAM-UNIT', 

  get_data_prc_orderpdam_notes      : 'GET-DATA-PRC-ORDERPDAM-NOTES', 
  pos_data_prc_orderpdam_notes      : 'POS-DATA-PRC-ORDERPDAM-NOTES',

  get_data_siteplanunit_progress            : 'GET-DATA-PRC-SITEPLANUNIT-PROGRESS', 
  get_data_siteplanunit_qualitycontrol      : 'GET-DATA-PRC-SITEPLANUNIT-QUALITYCONTROL', 
  get_data_siteplannonunit_progress         : 'GET-DATA-PRC-SITEPLANNONUNIT-PROGRESS', 
  get_data_siteplannonunit_progressbyvolume : 'GET-DATA-PRC-SITEPLANNONUNIT-PROGRESSBYVOLUME', 
  get_data_siteplannonunit_qualitycontrol   : 'GET-DATA-PRC-SITEPLANNONUNIT-QUALITYCONTROL', 

  // pur
  get_data_pur_supplier               : 'GET-DATA-PUR-SUPPLIER', 
  pos_data_pur_supplier               : 'POS-DATA-PUR-SUPPLIER', 
  put_data_pur_supplier               : 'PUT-DATA-PUR-SUPPLIER', 

  get_data_pur_subkon                 : 'GET-DATA-PUR-SUBKON', 
  pos_data_pur_subkon                 : 'POS-DATA-PUR-SUBKON', 
  put_data_pur_subkon                 : 'PUT-DATA-PUR-SUBKON', 

  get_data_pur_spk                    : 'GET-DATA-PUR-SPK',
  get_data_pur_spk_spesifikasi        : 'GET-DATA-PUR-SPKSPESIFIKASI', 
  get_data_pur_spk_termin             : 'GET-DATA-PUR-SPKTERMIN', 
  get_data_pur_spk_unit               : 'GET-DATA-PUR-SPKUNIT', 
  get_data_pur_spk_nonunit            : 'GET-DATA-PUR-SPKNONUNIT', 
  get_data_pur_spk_notes              : 'GET-DATA-PUR-SPKNOTES', 
  get_data_pur_spk_bap                : 'GET-DATA-PUR-SPKBAP', 

  get_cetak_data_pur_spk              : 'GET-CETAK-DATA-PUR-SPK',

  pos_data_pur_spk                    : 'POS-DATA-PUR-SPK', 
  pos_data_pur_spk_spesifikasi        : 'POS-DATA-PUR-SPKSPESIFIKASI', 
  pos_data_pur_spk_spesifikasigenerate: 'POS-DATA-PUR-SPKSPESIFIKASIGENERATE', 
  pos_data_pur_spk_termingenerate     : 'POS-DATA-PUR-SPKTERMINGENERATE', 
  pos_data_pur_spk_unit               : 'POS-DATA-PUR-SPKUNIT', 
  pos_data_pur_spk_nonunit            : 'POS-DATA-PUR-SPKNONUNIT', 
  pos_data_pur_spk_notes              : 'POS-DATA-PUR-SPKNOTES', 
  pos_data_pur_spk_bap                : 'POS-DATA-PUR-SPKBAP', 

  put_data_pur_spk                    : 'PUT-DATA-PUR-SPK', 
  put_data_pur_spk_pengesahan         : 'PUT-DATA-PUR-SPKPENGESAHAN', 
  put_data_pur_spk_spesifikasi        : 'PUT-DATA-PUR-SPKSPESIFIKASI', 
  put_data_pur_spk_unit               : 'PUT-DATA-PUR-SPKUNIT', 
  put_data_pur_spk_nonunit            : 'PUT-DATA-PUR-SPKNONUNIT', 
  put_data_pur_spk_bap                : 'PUT-DATA-PUR-SPKBAP', 

  del_data_pur_spk_spesifikasi        : 'DEL-DATA-PUR-SPKSPESIFIKASI', 
  del_data_pur_spk_unit               : 'DEL-DATA-PUR-SPKUNIT', 
  del_data_pur_spk_nonunit            : 'DEL-DATA-PUR-SPKNONUNIT', 

  get_data_pur_bap                    : 'GET-DATA-PUR-BAP', 
  get_data_pur_bap_pembayaran         : 'GET-DATA-PUR-BAPPEMBAYARAN', 
  get_data_pur_bap_pengurang          : 'GET-DATA-PUR-BAPPENGURANG', 

  put_data_pur_bap_status             : 'PUT-DATA-PUR-BAPSTATUS',

  pos_data_pur_bap_pembayaran         : 'POS-DATA-PUR-BAPPEMBAYARAN',
  put_data_pur_bap_pembayaran         : 'PUT-DATA-PUR-BAPPEMBAYARAN',
  del_data_pur_bap_pembayaran         : 'DEL-DATA-PUR-BAPPEMBAYARAN',

  pos_data_pur_bap_pengurang          : 'POS-DATA-PUR-BAPPENGURANG',
  put_data_pur_bap_pengurang          : 'PUT-DATA-PUR-BAPPENGURANG',
  del_data_pur_bap_pengurang          : 'DEL-DATA-PUR-BAPPENGURANG',

  // marketing
  get_data_mkt_unit                   : 'GET-DATA-MKT-UNIT',
  put_data_mkt_unit_harga             : 'PUT-DATA-MKT-UNITHARGA',
  put_data_mkt_unit_hargaglobal       : 'PUT-DATA-MKT-UNITHARGAGLOBAL',

  get_data_mkt_promo                        : 'GET-DATA-MKT-PROMO',
  pos_data_mkt_promo                        : 'POS-DATA-MKT-PROMO',
  put_data_mkt_promo                        : 'PUT-DATA-MKT-PROMO',

  get_data_mkt_promo_unit                   : 'GET-DATA-MKT-PROMO-UNIT',
  pos_data_mkt_promo_unit                   : 'POS-DATA-MKT-PROMO-UNIT',
  del_data_mkt_promo_unit                   : 'DEL-DATA-MKT-PROMO-UNIT',

  get_data_mkt_penjualan                    : 'GET-DATA-MKT-PENJUALAN', 
  get_data_mkt_penjualan_bankpemroses       : 'GET-DATA-MKT-PENJUALAN-BANKPEMROSES',
  get_data_mkt_penjualan_biaya              : 'GET-DATA-MKT-PENJUALAN-BIAYA',
  get_data_mkt_penjualan_diskon             : 'GET-DATA-MKT-PENJUALAN-DISKON',
  get_data_mkt_penjualan_notes              : 'GET-DATA-MKT-PENJUALAN-NOTES',
  get_data_mkt_penjualan_pembayaran         : 'GET-DATA-MKT-PENJUALAN-PEMBAYARAN',
  get_data_mkt_penjualan_sp3k               : 'GET-DATA-MKT-PENJUALAN-SP3K',

  get_data_mkt_penjualan_pembayaran_belumapprove  : 'GET-DATA-MKT-PENJUALAN-PEMBAYARAN-BELUMAPPROVE',
  put_data_mkt_penjualan_pembayaran_approve       : 'PUT-DATA-MKT-PENJUALAN-PEMBAYARAN-APPROVE',
  put_data_mkt_penjualan_pembayaran_rollback      : 'PUT-DATA-MKT-PENJUALAN-PEMBAYARAN-ROLLBACK',
  
  pos_data_mkt_penjualan_diskon             : 'POS-DATA-MKT-PENJUALAN-DISKON',
  put_data_mkt_penjualan_diskon             : 'PUT-DATA-MKT-PENJUALAN-DISKON',
  del_data_mkt_penjualan_diskon             : 'DEL-DATA-MKT-PENJUALAN-DISKON',

  pos_data_mkt_penjualan_notes              : 'POS-DATA-MKT-PENJUALAN-NOTES',

  // keuangan
  keu_get_data_pur_bap_pembayaran           : 'KEU-GET-DATA-PUR-BAP-PEMBAYARAN', 
  keu_get_daftartugas_pur_bap_pembayaran    : 'KEU-GET-DAFTARTUGAS-PUR-BAP-PEMBAYARAN', 

  // hrd
  get_data_hrd_karyawan               : 'GET-DATA-HRD-KARYAWAN', 
  pos_data_hrd_karyawan               : 'POS-DATA-HRD-KARYAWAN', 
  put_data_hrd_karyawan               : 'PUT-DATA-HRD-KARYAWAN', 

  get_data_hrd_karyawan_nextnik       : 'GET-DATA-HRD-KARYAWAN-NEXTNIK', 
  get_data_hrd_karyawan_checknama     : 'GET-DATA-HRD-KARYAWAN-CHECKNAMA', 

  get_data_hrd_karyawan_riwayat       : 'GET-DATA-HRD-KARYAWAN-RIWAYAT', 

  get_data_hrd_karyawan_notes         : 'GET-DATA-HRD-KARYAWAN-NOTES', 
  pos_data_hrd_karyawan_notes         : 'POS-DATA-HRD-KARYAWAN-NOTES', 

  get_data_hrd_karyawan_kontrakhabis                : 'GET-DATA-HRD-KARYAWAN-KONTRAKHABIS', 
  get_data_hrd_karyawan_emptybpjstenagakerja        : 'GET-DATA-HRD-KARYAWAN-EMPTYBPJSTENAGAKERJA', 
  get_data_hrd_karyawan_resignaktifbpjstenagakerja  : 'GET-DATA-HRD-KARYAWAN-RESIGNAKTIFBPJSTENAGAKERJA', 

  get_data_hrd_titikabsensi                 : 'GET-DATA-HRD-TITIKABSENSI', 
  pos_data_hrd_titikabsensi                 : 'POS-DATA-HRD-TITIKABSENSI', 
  put_data_hrd_titikabsensi                 : 'PUT-DATA-HRD-TITIKABSENSI', 

  get_data_hrd_absensi_hariliburnasional    : 'GET-DATA-HRD-ABSENSI-HARILIBURNASIONAL', 
  pos_data_hrd_absensi_hariliburnasional    : 'POS-DATA-HRD-ABSENSI-HARILIBURNASIONAL', 
  put_data_hrd_absensi_hariliburnasional    : 'PUT-DATA-HRD-ABSENSI-HARILIBURNASIONAL', 

  get_data_hrd_absensi                      : 'GET-DATA-HRD-ABSENSI', 
  pos_data_hrd_absensi                      : 'POS-DATA-HRD-ABSENSI', 
  put_data_hrd_absensi                      : 'PUT-DATA-HRD-ABSENSI', 


  get_data_hrd_absensi_timetable            : 'GET-DATA-HRD-ABSENSI-TIMETABLE', 
  get_data_hrd_absensi_prosestimetable      : 'GET-DATA-HRD-ABSENSI-PROSESTIMETABLE', 
  get_data_hrd_absensi_rekapharian          : 'GET-DATA-HRD-ABSENSI-REKAPHARIAN', 

  get_data_hrd_trx_penerimaan                     : 'GET-DATA-HRD-TRX-PENERIMAAN',
  get_data_hrd_trx_penerimaan_karyawan            : 'GET-DATA-HRD-TRX-PENERIMAAN-KARYAWAN',
  get_data_hrd_trx_mutasi                         : 'GET-DATA-HRD-TRX-MUTASI',
  get_data_hrd_trx_mutasi_karyawan                : 'GET-DATA-HRD-TRX-MUTASI-KARYAWAN',
  get_data_hrd_trx_pelatihan                      : 'GET-DATA-HRD-TRX-PELATIHAN',
  get_data_hrd_trx_pelatihan_karyawan             : 'GET-DATA-HRD-TRX-PELATIHAN-KARYAWAN',
  get_data_hrd_trx_penghargaan                    : 'GET-DATA-HRD-TRX-PENGHARGAAN',
  get_data_hrd_trx_penghargaan_karyawan           : 'GET-DATA-HRD-TRX-PENGHARGAAN-KARYAWAN',
  get_data_hrd_trx_sanksi                         : 'GET-DATA-HRD-TRX-SANKSI',
  get_data_hrd_trx_sanksi_karyawan                : 'GET-DATA-HRD-TRX-SANKSI-KARYAWAN',
  get_data_hrd_trx_pemutusankerja                 : 'GET-DATA-HRD-TRX-PEMUTUSANKERJA',
  get_data_hrd_trx_pemutusankerja_karyawan        : 'GET-DATA-HRD-TRX-PEMUTUSANKERJA-KARYAWAN',
  get_data_hrd_trx_perpanjangankontrak            : 'GET-DATA-HRD-TRX-PERPANJANGANKONTRAK',
  get_data_hrd_trx_pengangkatan                   : 'GET-DATA-HRD-TRX-PENGANGKATAN',

  pos_data_hrd_trx_penerimaan                     : 'POS-DATA-HRD-TRX-PENERIMAAN',
  pos_data_hrd_trx_penerimaan_karyawan            : 'POS-DATA-HRD-TRX-PENERIMAAN-KARYAWAN',
  pos_data_hrd_trx_penerimaan_karyawan_proses     : 'POS-DATA-HRD-TRX-PENERIMAAN-KARYAWAN-PROSES',
  pos_data_hrd_trx_mutasi                         : 'POS-DATA-HRD-TRX-MUTASI',
  pos_data_hrd_trx_mutasi_karyawan                : 'POS-DATA-HRD-TRX-MUTASI-KARYAWAN',
  pos_data_hrd_trx_mutasi_karyawan_proses         : 'POS-DATA-HRD-TRX-MUTASI-KARYAWAN-PROSES',
  pos_data_hrd_trx_pelatihan                      : 'POS-DATA-HRD-TRX-PELATIHAN',
  pos_data_hrd_trx_pelatihan_karyawan             : 'POS-DATA-HRD-TRX-PELATIHAN-KARYAWAN',
  pos_data_hrd_trx_pelatihan_karyawan_proses      : 'POS-DATA-HRD-TRX-PELATIHAN-KARYAWAN-PROSES',
  pos_data_hrd_trx_penghargaan                    : 'POS-DATA-HRD-TRX-PENGHARGAAN',
  pos_data_hrd_trx_penghargaan_karyawan           : 'POS-DATA-HRD-TRX-PENGHARGAAN-KARYAWAN',
  pos_data_hrd_trx_penghargaan_karyawan_proses    : 'POS-DATA-HRD-TRX-PENGHARGAAN-KARYAWAN-PROSES',
  pos_data_hrd_trx_sanksi                         : 'POS-DATA-HRD-TRX-SANKSI',
  pos_data_hrd_trx_sanksi_karyawan                : 'POS-DATA-HRD-TRX-SANKSI-KARYAWAN',
  pos_data_hrd_trx_sanksi_karyawan_proses         : 'POS-DATA-HRD-TRX-SANKSI-KARYAWAN-PROSES',
  pos_data_hrd_trx_pemutusankerja                 : 'POS-DATA-HRD-TRX-PEMUTUSANKERJA',
  pos_data_hrd_trx_pemutusankerja_karyawan        : 'POS-DATA-HRD-TRX-PEMUTUSANKERJA-KARYAWAN',
  pos_data_hrd_trx_pemutusankerja_karyawan_proses : 'POS-DATA-HRD-TRX-PEMUTUSANKERJA-KARYAWAN-PROSES',
  pos_data_hrd_trx_perpanjangankontrak            : 'POS-DATA-HRD-TRX-PERPANJANGANKONTRAK',
  pos_data_hrd_trx_perpanjangankontrak_karyawan_proses     : 'POS-DATA-HRD-TRX-PERPANJANGANKONTRAK-KARYAWAN-PROSES',
  pos_data_hrd_trx_pengangkatan                   : 'POS-DATA-HRD-TRX-PENGANGKATAN',
  pos_data_hrd_trx_pengangkatan_karyawan_proses   : 'POS-DATA-HRD-TRX-PENGANGKATAN-KARYAWAN-PROSES',

  put_data_hrd_trx_penerimaan                     : 'PUT-DATA-HRD-TRX-PENERIMAAN',
  put_data_hrd_trx_penerimaan_karyawan            : 'PUT-DATA-HRD-TRX-PENERIMAAN-KARYAWAN',
  put_data_hrd_trx_mutasi                         : 'PUT-DATA-HRD-TRX-MUTASI',
  put_data_hrd_trx_mutasi_karyawan                : 'PUT-DATA-HRD-TRX-MUTASI-KARYAWAN',
  put_data_hrd_trx_pelatihan                      : 'PUT-DATA-HRD-TRX-PELATIHAN',
  put_data_hrd_trx_pelatihan_karyawan             : 'PUT-DATA-HRD-TRX-PELATIHAN-KARYAWAN',
  put_data_hrd_trx_penghargaan                    : 'PUT-DATA-HRD-TRX-PENGHARGAAN',
  put_data_hrd_trx_penghargaan_karyawan           : 'PUT-DATA-HRD-TRX-PENGHARGAAN-KARYAWAN',
  put_data_hrd_trx_sanksi                         : 'PUT-DATA-HRD-TRX-SANKSI',
  put_data_hrd_trx_sanksi_karyawan                : 'PUT-DATA-HRD-TRX-SANKSI-KARYAWAN',
  put_data_hrd_trx_pemutusankerja                 : 'PUT-DATA-HRD-TRX-PEMUTUSANKERJA',
  put_data_hrd_trx_pemutusankerja_karyawan        : 'PUT-DATA-HRD-TRX-PEMUTUSANKERJA-KARYAWAN',
  put_data_hrd_trx_perpanjangankontrak            : 'PUT-DATA-HRD-TRX-PERPANJANGANKONTRAK',
  put_data_hrd_trx_pengangkatan                   : 'PUT-DATA-HRD-TRX-PENGANGKATAN',

  del_data_hrd_trx_penerimaan_karyawan            : 'DEL-DATA-HRD-TRX-PENERIMAAN-KARYAWAN',
  del_data_hrd_trx_mutasi_karyawan                : 'DEL-DATA-HRD-TRX-MUTASI-KARYAWAN',
  del_data_hrd_trx_pelatihan_karyawan             : 'DEL-DATA-HRD-TRX-PELATIHAN-KARYAWAN',
  del_data_hrd_trx_penghargaan_karyawan           : 'DEL-DATA-HRD-TRX-PENGHARGAAN-KARYAWAN',
  del_data_hrd_trx_sanksi_karyawan                : 'DEL-DATA-HRD-TRX-SANKSI-KARYAWAN',
  del_data_hrd_trx_pemutusankerja_karyawan        : 'DEL-DATA-HRD-TRX-PEMUTUSANKERJA-KARYAWAN',

  get_data_hrd_absensi_kategori_jamabsen          : 'GET-DATA-HRD-ABSENSI-KATEGORI-JAMABSEN', 
  pos_data_hrd_absensi_kategori_jamabsen          : 'POS-DATA-HRD-ABSENSI-KATEGORI-JAMABSEN', 
  put_data_hrd_absensi_kategori_jamabsen          : 'PUT-DATA-HRD-ABSENSI-KATEGORI-JAMABSEN', 

  get_data_hrd_absensi_kategori_jamabsen_karyawan : 'GET-DATA-HRD-ABSENSI-KATEGORI-JAMABSEN-KARYAWAN', 
  pos_data_hrd_absensi_kategori_jamabsen_karyawan : 'POS-DATA-HRD-ABSENSI-KATEGORI-JAMABSEN-KARYAWAN', 
  del_data_hrd_absensi_kategori_jamabsen_karyawan : 'DEL-DATA-HRD-ABSENSI-KATEGORI-JAMABSEN-KARYAWAN', 

  get_data_hrd_absensi_kategori_harikerja          : 'GET-DATA-HRD-ABSENSI-KATEGORI-HARIKERJA', 
  pos_data_hrd_absensi_kategori_harikerja          : 'POS-DATA-HRD-ABSENSI-KATEGORI-HARIKERJA', 
  put_data_hrd_absensi_kategori_harikerja          : 'PUT-DATA-HRD-ABSENSI-KATEGORI-HARIKERJA', 

  get_data_hrd_absensi_kategori_harikerja_karyawan : 'GET-DATA-HRD-ABSENSI-KATEGORI-HARIKERJA-KARYAWAN', 
  pos_data_hrd_absensi_kategori_harikerja_karyawan : 'POS-DATA-HRD-ABSENSI-KATEGORI-HARIKERJA-KARYAWAN', 
  del_data_hrd_absensi_kategori_harikerja_karyawan : 'DEL-DATA-HRD-ABSENSI-KATEGORI-HARIKERJA-KARYAWAN', 

  get_data_hrd_absensi_kategori_harikerja_periode  : 'GET-DATA-HRD-ABSENSI-KATEGORI-HARIKERJA-PERIODE', 
  pos_data_hrd_absensi_kategori_harikerja_periode  : 'POS-DATA-HRD-ABSENSI-KATEGORI-HARIKERJA-PERIODE', 
  put_data_hrd_absensi_kategori_harikerja_periode  : 'PUT-DATA-HRD-ABSENSI-KATEGORI-HARIKERJA-PERIODE', 

  get_data_hrd_absensi_kategori_harikerja_jamabsen : 'GET-DATA-HRD-ABSENSI-KATEGORI-HARIKERJA-JAMABSEN', 
  put_data_hrd_absensi_kategori_harikerja_jamabsen : 'PUT-DATA-HRD-ABSENSI-KATEGORI-HARIKERJA-JAMABSEN', 


  pos_data_hrd_absensi_timetable_rekap             : 'POS-DATA-HRD-ABSENSI-TIMETABLE-REKAP',
  pos_data_hrd_absensi_proses_log                  : 'POS-DATA-HRD-ABSENSI-PROSES-LOG', 
  pos_data_hrd_absensi_proses_rekap_harian         : 'POS-DATA-HRD-ABSENSI-PROSES-REKAP-HARIAN', 
  pos_data_hrd_absensi_proses_rekap_keuangan       : 'POS-DATA-HRD-ABSENSI-PROSES-REKAP-KEUANGAN', 

  put_data_hrd_absensi_proses_log                  : 'PUT-DATA-HRD-ABSENSI-PROSES-LOG', 
  // put_data_hrd_absensi_proses_rekap_harian         : 'PUT-DATA-HRD-ABSENSI-PROSES-REKAP-HARIAN', 
  put_data_hrd_absensi_proses_rekap_keuangan       : 'PUT-DATA-HRD-ABSENSI-PROSES-REKAP-KEUANGAN', 

  put_data_hrd_karyawan_cuti                       : 'PUT-DATA-HRD-KARYAWAN-CUTI', 

  // cabang
  cab_get_data_cab_freelance                  : 'CAB-GET-DATA-CAB-FREELANCE', 
  cab_pos_data_cab_freelance                  : 'CAB-POS-DATA-CAB-FREELANCE', 
  cab_put_data_cab_freelance                  : 'CAB-PUT-DATA-CAB-FREELANCE', 
  
  cab_get_data_hrd_karyawan                   : 'CAB-GET-DATA-HRD-KARYAWAN',

  cab_get_data_prc_siteplan                   : 'CAB-GET-DATA-PRC-SITEPLAN',
  cab_get_data_prc_siteplanunit               : 'CAB-GET-DATA-PRC-SITEPLANUNIT',
  cab_get_data_prc_siteplannonunit            : 'CAB-GET-DATA-PRC-SITEPLANNONUNIT',

  cab_put_data_prc_siteplanunit_pengawas      : 'CAB-PUT-DATA-PRC-SITEPLANUNIT-PENGAWAS',
  cab_put_data_prc_siteplannonunit_pengawas   : 'CAB-PUT-DATA-PRC-SITEPLANNONUNIT-PENGAWAS',

  cab_get_data_mkt_penjualan                  : 'CAB-GET-DATA-MKT-PENJUALAN', 
  cab_get_data_mkt_penjualan_bankpemroses     : 'CAB-GET-DATA-MKT-PENJUALAN-BANKPEMROSES',
  cab_get_data_mkt_penjualan_biaya            : 'CAB-GET-DATA-MKT-PENJUALAN-BIAYA',
  cab_get_data_mkt_penjualan_diskon           : 'CAB-GET-DATA-MKT-PENJUALAN-DISKON',
  cab_get_data_mkt_penjualan_notes            : 'CAB-GET-DATA-MKT-PENJUALAN-NOTES',
  cab_get_data_mkt_penjualan_pembayaran       : 'CAB-GET-DATA-MKT-PENJUALAN-PEMBAYARAN',
  cab_get_data_mkt_penjualan_sp3k             : 'CAB-GET-DATA-MKT-PENJUALAN-SP3K',

  cab_pos_data_mkt_penjualan                  : 'CAB-POS-DATA-MKT-PENJUALAN',
  cab_pos_data_mkt_penjualan_bankpemroses     : 'CAB-POS-DATA-MKT-PENJUALAN-BANKPEMROSES',
  cab_pos_data_mkt_penjualan_biaya_generate   : 'CAB-POS-DATA-MKT-PENJUALAN-BIAYA-GENERATE',
  cab_pos_data_mkt_penjualan_biaya            : 'CAB-POS-DATA-MKT-PENJUALAN-BIAYA',
  cab_pos_data_mkt_penjualan_diskon           : 'CAB-POS-DATA-MKT-PENJUALAN-DISKON',
  cab_pos_data_mkt_penjualan_notes            : 'CAB-POS-DATA-MKT-PENJUALAN-NOTES',
  cab_pos_data_mkt_penjualan_pembayaran       : 'CAB-POS-DATA-MKT-PENJUALAN-PEMBAYARAN',
  cab_pos_data_mkt_penjualan_sp3k             : 'CAB-POS-DATA-MKT-PENJUALAN-SP3K',

  cab_put_data_mkt_penjualan_jenistransaksi   : 'CAB-PUT-DATA-MKT-PENJUALAN-JENISTRANSAKSI',
  cab_put_data_mkt_penjualan_konsumen         : 'CAB-PUT-DATA-MKT-PENJUALAN-KONSUMEN',
  cab_put_data_mkt_penjualan_psjb             : 'CAB-PUT-DATA-MKT-PENJUALAN-PSJB',
  cab_put_data_mkt_penjualan_berkaslengkap    : 'CAB-PUT-DATA-MKT-PENJUALAN-BERKASLENGKAP',
  cab_put_data_mkt_penjualan_bicmasuk         : 'CAB-PUT-DATA-MKT-PENJUALAN-BICMASUK',
  cab_put_data_mkt_penjualan_bickeluar        : 'CAB-PUT-DATA-MKT-PENJUALAN-BICKELUAR',
  cab_put_data_mkt_penjualan_ppjb             : 'CAB-PUT-DATA-MKT-PENJUALAN-PPJB',
  cab_put_data_mkt_penjualan_ajb              : 'CAB-PUT-DATA-MKT-PENJUALAN-AJB',
  cab_put_data_mkt_penjualan_serahterima      : 'CAB-PUT-DATA-MKT-PENJUALAN-SERAHTERIMA',
  cab_put_data_mkt_penjualan_selesai          : 'CAB-PUT-DATA-MKT-PENJUALAN-SELESAI',
  cab_put_data_mkt_penjualan_plafond          : 'CAB-PUT-DATA-MKT-PENJUALAN-PLAFOND',
  cab_put_data_mkt_penjualan_sbum             : 'CAB-PUT-DATA-MKT-PENJUALAN-SBUM',
  cab_put_data_mkt_penjualan_pembatalan       : 'CAB-PUT-DATA-MKT-PENJUALAN-PEMBATALAN',

  cab_put_data_mkt_penjualan_bankpemroses     : 'CAB-PUT-DATA-MKT-PENJUALAN-BANKPEMROSES',
  cab_put_data_mkt_penjualan_biaya            : 'CAB-PUT-DATA-MKT-PENJUALAN-BIAYA',
  cab_put_data_mkt_penjualan_diskon           : 'CAB-PUT-DATA-MKT-PENJUALAN-DISKON',
  cab_put_data_mkt_penjualan_pembayaran       : 'CAB-PUT-DATA-MKT-PENJUALAN-PEMBAYARAN',
  cab_put_data_mkt_penjualan_sp3k             : 'CAB-PUT-DATA-MKT-PENJUALAN-SP3K',

  cab_del_data_mkt_penjualan_bankpemroses     : 'CAB-DEL-DATA-MKT-PENJUALAN-BANKPEMROSES',
  cab_del_data_mkt_penjualan_biaya            : 'CAB-DEL-DATA-MKT-PENJUALAN-BIAYA',
  cab_del_data_mkt_penjualan_diskon           : 'CAB-DEL-DATA-MKT-PENJUALAN-DISKON',
  cab_del_data_mkt_penjualan_pembayaran       : 'CAB-DEL-DATA-MKT-PENJUALAN-PEMBAYARAN',
  cab_del_data_mkt_penjualan_sp3k             : 'CAB-DEL-DATA-MKT-PENJUALAN-SP3K',

  cab_get_data_prc_siteplanunit_progress            : 'CAB-GET-DATA-PRC-SITEPLANUNIT-PROGRESS', 
  cab_pos_data_prc_siteplanunit_progress_generate   : 'CAB-POS-DATA-PRC-SITEPLANUNIT-PROGRESS-GENERATE', 
  cab_put_data_prc_siteplanunit_progress            : 'CAB-PUT-DATA-PRC-SITEPLANUNIT-PROGRESS', 

  cab_get_data_prc_siteplanunit_qualitycontrol            : 'CAB-GET-DATA-PRC-SITEPLANUNIT-QUALITYCONTROL', 
  cab_pos_data_prc_siteplanunit_qualitycontrol_generate   : 'CAB-POS-DATA-PRC-SITEPLANUNIT-QUALITYCONTROL-GENERATE', 
  cab_put_data_prc_siteplanunit_qualitycontrol            : 'CAB-PUT-DATA-PRC-SITEPLANUNIT-QUALITYCONTROL', 

  // REPORT
  get_report_prc_progressunit               : 'GET-REPORT-PRC-PROGRESSUNIT', 
  get_report_mkt_siteplanpenjualan          : 'GET-REPORT-MKT-SITEPLANPENJUALAN', 
  
  // // OLD
  // // direktur
  // direktur_get_users_profile_roles    : 'DIREKTUR-GET-USERS-PROFILE-ROLES',
  // direktur_update_users_profile_roles : 'DIREKTUR-UPDATE-USERS-PROFILE-ROLES',

  // direktur_get_data_perusahaan        : 'DIREKTUR-GET-DATA-PERUSAHAAN',
  // direktur_create_data_perusahaan     : 'DIREKTUR-CREATE-DATA-PERUSAHAAN',
  // direktur_update_data_perusahaan     : 'DIREKTUR-UPDATE-DATA-PERUSAHAAN',

  // direktur_get_data_cabang            : 'DIREKTUR-GET-DATA-CABANG',
  // direktur_create_data_cabang         : 'DIREKTUR-CREATE-DATA-CABANG',
  // direktur_update_data_cabang         : 'DIREKTUR-UPDATE-DATA-CABANG',

  // direktur_get_data_spk                                     : 'DIREKTUR-GET-DATA-SPK',
  // direktur_get_data_spk_termin                              : 'DIREKTUR-GET-DATA-SPK-TERMIN',
  // direktur_get_data_spk_spesifikasi                         : 'DIREKTUR-GET-DATA-SPK-SPESIFIKASI',
  // direktur_get_data_spk_unit                                : 'DIREKTUR-GET-DATA-SPK-UNIT',
  // direktur_get_data_spk_non_unit                            : 'DIREKTUR-GET-DATA-SPK-NON-UNIT',

  // direktur_update_data_spk_approve                          : 'DIREKTUR-UPDATE-DATA-SPK-APPROVE',

  // direktur_get_data_spk_history                             : 'DIREKTUR-GET-DATA-SPK-HISTORY',

}