import ServerCall from "./ServerCall"
import api_calls from "./constants/api"
import { COMMAND_SERVER } from "./constants/server"

//
//
// not registered
//================================================================================================
const register = async (oCredentials) => {
  await SendMessageToServer(api_calls.endpoint, COMMAND_SERVER.register, '', JSON.stringify({credentials:{...oCredentials}}))
}

//
//
// signin signout
const signInFirebase = async (oCredentials, oDevice) => {
  let returnServer = await SendMessageToServer(api_calls.endpoint, COMMAND_SERVER.user_post_login_firebase, '', JSON.stringify({credentials:{...oCredentials}, device: {...oDevice}}))
  return returnServer
} 

const signInUserPassword = async (oCredentials, oDevice) => {
  let returnServer = await SendMessageToServer(api_calls.endpoint, COMMAND_SERVER.user_post_login_usernamepassword, '', JSON.stringify({credentials:{...oCredentials}, device: {...oDevice}}))
  return returnServer
} 

const signOut = async (oCredentials) => {
  let returnServer = await SendMessageToServer(api_calls.endpoint, COMMAND_SERVER.user_post_logout, oCredentials.auth_token, JSON.stringify({}))
  return returnServer
}

//
//
// registered
//================================================================================================
const getUserProfile = async (oCredentials) => {
  let returnServer = await SendMessageToServer(api_calls.endpoint, COMMAND_SERVER.user_get_profile, oCredentials.auth_token, JSON.stringify({}))
  return returnServer
}

const postUpdateProfile = async (oCredentials,oData) => {
  let returnServer = await SendMessageToServer(api_calls.endpoint, COMMAND_SERVER.user_update_profile, oCredentials.auth_token, JSON.stringify({profile: {...oData}}))
  return returnServer
}


//
//
// send message to server
//================================================================================================
const SendMessageToServer = async (oEndpoint, action, authToken, data) => {
  try {
    let returnServer = await ServerCall.call(oEndpoint, {
                    action      : action,
                    auth_token  : authToken,
                    data        : data
                  })
    
    return returnServer
  } catch (error) {
    console.log('ServerApiAuth::SendMessageToServer', error)
  }
  return null
}

//
//
// interface
//================================================================================================
const ServerApiAuth = {
  register: async (oCredentials) => { return register(oCredentials) },

  signInFirebase: async(oCredentials, oDevice) => { return signInFirebase(oCredentials, oDevice) },
  signInUserPassword: async(oCredentials, oDevice) => { return signInUserPassword(oCredentials, oDevice) },
  signOut: async(oCredentials) => { return signOut(oCredentials) },

  getUserProfile: async (oCredentials) => { return getUserProfile(oCredentials) },
  postUpdateProfile: async (oCredentials, oData) => { return postUpdateProfile(oCredentials, oData) }
}

export default ServerApiAuth